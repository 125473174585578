

function PrivacyCookies(cookieName, cookieDomain) {

    var scope = this;

    this.information = {
        cookieName: cookieName,
        cookieDomain: cookieDomain,
        shown: 'Shown',
        shownNecessary: 'Shown_Necessary',
        agreedNecessary: 'Agreed_Necessary',
        shownAgreed: 'Shown_Agreed',
        cookieDuration: 365
    };

    this.getCookie = function(cookieName) {
        var name = cookieName + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    this.setCookie = function(cookieName, cookieValue, expiresInDays) {
        var d = new Date();
        d.setTime(d.getTime() + (expiresInDays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";domain=" + scope.information.cookieDomain + ";path=/";
    };

    this.hasCookie = function() {
        return scope.getCookie(scope.information.cookieName) != '';
    };

    if (!scope.hasCookie()) {

        scope.setCookie(
            scope.information.cookieName,
            scope.information.shown,
            scope.information.cookieDuration)
        ;

    } else if (scope.getCookie(scope.information.cookieName) == scope.information.shown) {

        scope.setCookie(
            scope.information.cookieName,
            scope.information.shownNecessary,
            scope.information.cookieDuration)
        ;

    }

}
